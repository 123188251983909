<template>
  <div
    class="p-6 mx-auto page max-w-2xl print:max-w-letter md:max-w-letter md:h-letter xsm:p-8 sm:p-9 md:p-16 bg-white">
    <header class="flex items-center mb-8 md:mb-11">
      <div class="initials-container mr-5 text-base leading-none text-white bg-gray-700 font-medium print:bg-black px-3"
           style="padding-bottom: 0.6875rem; padding-top: 0.6875rem; /*11px*/">
        <div class="initial text-center" style="padding-bottom: 0.1875rem; /*3px*/">A</div>
        <div class="text-center initial">S</div>
      </div>
      <h1 class="text-2xl font-semibold text-gray-750 pb-px">
        Ali Sarfaraz
      </h1>
    </header>
    <div class="md:col-count-2 print:col-count-2 col-gap-md md:h-letter-col print:h-letter-col col-fill-auto grid lg:grid-cols-2 gap-6">
      <section class="mt-8">
        <div class="break-inside-avoid">
          <h2 class="mb-4 font-bold tracking-widest text-sm2 text-gray-550 print:font-normal">
            ABOUT ME
          </h2>
          <section class="mb-4.5 break-inside-avoid">
            <header>
              <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
                Web Developer
              </h3>
              <p class="leading-normal text-md text-gray-650">
                Since 2013
              </p>
            </header>
            <p class="mt-2.1 text-md text-gray-700 leading-normal">
              Senior software engineer with over seven years of experience, strong project leadership and mentoring skills. Passionate about providing SEO and accessibility focused web experiences using agile development.
            </p>
          </section>
          <section class="mb-4.5 break-inside-avoid">
            <ul class="list-inside pr-7">
              <li class="mt-1.5 leading-normal text-gray-700 text-md">
                <a href="https://www.ashmportfolio.com" class="group">
                  www.ashmportfolio.com
                  <span
                    class="inline-block text-gray-550 print:text-black font-normal group-hover:text-gray-700 transition duration-100 ease-in">↗</span>
                </a>
              </li>
              <li class="mt-1.5 leading-normal text-gray-700 text-md">
                <a href="https://github.com/asarfara" class="group">
                  Github
                  <span
                    class="inline-block text-gray-550 print:text-black font-normal group-hover:text-gray-700 transition duration-100 ease-in">↗</span>
                </a>
              </li>
              <li class="mt-1.5 leading-normal text-gray-700 text-md">
                <a href="https://www.npmjs.com/~asarfara" class="group">
                  NPM
                  <span
                    class="inline-block text-gray-550 print:text-black font-normal group-hover:text-gray-700 transition duration-100 ease-in">↗</span>
                </a>
              </li>
              <li class="mt-1.5 leading-normal text-gray-700 text-md">
                Lilydale, Victoria
              </li>
              <li class="mt-1.5 leading-normal text-gray-700 text-md">
                ali.sarfaraz88@gmail.com
              </li>
              <li class="mt-1.5 leading-normal text-gray-700 text-md">
                0425 154 826
              </li>
            </ul>
          </section>
          <section class="mb-4.5 mt-10 break-inside-avoid">
            <div class="break-inside-avoid">
              <h2 class="mb-4 font-bold tracking-widest text-sm2 text-gray-550 print:font-normal">
                EDUCATION
              </h2>
              <section class="mb-4.5 break-inside-avoid">
                <header>
                  <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
                    Deakin University, Burwood
                  </h3>
                  <p class="leading-normal text-md text-gray-650">
                    2011 – 2013 | Bachelors of Information Technology
                  </p>
                </header>
              </section>
            </div>
            <section class="mb-4.5 break-inside-avoid">
              <header>
                <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
                  Deakin University, Burwood
                </h3>
                <p class="leading-normal text-md text-gray-650">
                  2010 – 2011 | Diploma of Computing
                </p>
              </header>
            </section>
          </section>
          <section class="mb-4.5 mt-10 break-inside-avoid">
            <div class="break-inside-avoid">
              <h2 class="mb-4 font-bold tracking-widest text-sm2 text-gray-550 print:font-normal">
                SKILLS
              </h2>
            </div>
            <section class="mb-4.5 break-inside-avoid">
              <header>
                <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
                  PHP
                </h3>
              </header>
              <div class="my-3.2 last:pb-1.5">
                <ul class="flex flex-wrap text-md leading-relaxed -mr-1.6 -mb-1.6">
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Drupal 7/8
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Symfony 3/4
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Api Platform
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Laravel
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Wordpress
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Composer
                  </li>
                </ul>
              </div>
            </section>
            <section class="mb-4.5 break-inside-avoid">
              <header>
                <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
                  JavaScript
                </h3>
              </header>
              <div class="my-3.2 last:pb-1.5">
                <ul class="flex flex-wrap text-md leading-relaxed -mr-1.6 -mb-1.6">
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    ES6
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    VueJS
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    AngularJS
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    NuxtJS
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    NPM
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Webpack/Grunt/Gulp
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Babel
                  </li>
                </ul>
              </div>
            </section>
            <section class="mb-4.5 break-inside-avoid">
              <header>
                <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
                  DevOps
                </h3>
              </header>
              <div class="my-3.2 last:pb-1.5">
                <ul class="flex flex-wrap text-md leading-relaxed -mr-1.6 -mb-1.6">
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    AWS
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Platform.sh
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Vercel
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Azure Devops
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Github Actions
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Travis CI
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Circle CI
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Puppet
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Docker
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Nginx
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Section.io
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Cloudflare
                  </li>
                </ul>
              </div>
            </section>
            <section class="mb-4.5 break-inside-avoid">
              <header>
                <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
                  Testing
                </h3>
              </header>
              <div class="my-3.2 last:pb-1.5">
                <ul class="flex flex-wrap text-md leading-relaxed -mr-1.6 -mb-1.6">
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Cypress/Behat
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Karma/Mocha
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    PHP Unit
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Postman/Newman
                  </li>
                </ul>
              </div>
            </section>
            <section class="mb-4.5 break-inside-avoid">
              <header>
                <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
                  Search
                </h3>
              </header>
              <div class="my-3.2 last:pb-1.5">
                <ul class="flex flex-wrap text-md leading-relaxed -mr-1.6 -mb-1.6">
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Algolia
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Elasticsearch
                  </li>
                </ul>
              </div>
            </section>
            <section class="mb-4.5 break-inside-avoid">
              <header>
                <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
                  Logging
                </h3>
              </header>
              <div class="my-3.2 last:pb-1.5">
                <ul class="flex flex-wrap text-md leading-relaxed -mr-1.6 -mb-1.6">
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Kibana
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    SumoLogic
                  </li>
                </ul>
              </div>
            </section>
            <section class="mb-4.5 break-inside-avoid">
              <header>
                <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
                  Other
                </h3>
              </header>
              <div class="my-3.2 last:pb-1.5">
                <ul class="flex flex-wrap text-md leading-relaxed -mr-1.6 -mb-1.6">
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Auth0
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Marketing Cloud
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    HTML
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    CSS/LESS/SASS
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    BEM/SMACSS
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Tailwind CSS
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Material Design
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Bootstrap
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Git
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    MySql/MariaDB
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    Python
                  </li>
                  <li class="px-2.5 mr-1.6 mb-1.6 text-base text-gray-750 print:bg-white print:border-inset bg-gray-200">
                    RabbitMQ/ActiveMQ
                  </li>
                </ul>
              </div>
            </section>
          </section>
          <section class="mb-4.5 mt-10 break-inside-avoid">
            <div class="break-inside-avoid">
              <h2 class="mb-4 font-bold tracking-widest text-sm2 text-gray-550 print:font-normal">
                CERTIFICATIONS
              </h2>

              <section class="mb-4.5 break-inside-avoid">
                <header>
                  <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
                    Email Specialist Marketing Cloud
                  </h3>
                </header>
              </section>
            </div>
            <section class="mb-4.5 break-inside-avoid">
              <header>
                <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
                  Certified ScrumMaster
                </h3>
              </header>
            </section>
          </section>
        </div>
      </section>
      <section class="mt-8">
        <div class="break-inside-avoid">
          <h2 class="mb-4 font-bold tracking-widest text-sm2 text-gray-550 print:font-normal">
            EXPERIENCE
          </h2>
        </div>
        <section class="mb-4.5 break-inside-avoid">
          <header>
            <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
              Intrepid Group
            </h3>
            <p class="leading-normal text-md text-gray-650">
              April 2021 - Present | Senior Software Engineer
            </p>
          </header>
          <ul class="">

            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Responsible for maintaining and adding new technologies to the web
              development stack including Drupal 7/8, Symfony 3/4, VueJS, PHP7.3, Algolia,
              Auth0, AWS Stack, CI/CD pipelines, Cypress Integration, Accessibility Testing
              and Material Design Layout.
            </li>
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Responsible for mentoring junior software engineers to empower them to
              become more proficient over time in making better technical contributions.
            </li>
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Responsible for managing the delivery of work within sprint deadlines across
              sprint team without supervision or assistance.
            </li>
          </ul>
        </section>
        <section class="mb-4.5 break-inside-avoid">
          <header>
            <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
              Department of Premier and Cabinet (Vic)
            </h3>
            <p class="leading-normal text-md text-gray-650">
              June 2020 – April 2021 | Drupal Developer
            </p>
          </header>
          <ul class="">
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Responsible for maintaining and adding new technologies to the web
              development stack including Drupal 8, Lagoon, Openshift, AWS.
            </li>
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Responsible for building migrations
            </li>
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Responsible for deployments.
            </li>
          </ul>
        </section>
        <section class="mb-4.5 break-inside-avoid">
          <header>
            <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
              Intrepid Group
            </h3>
            <p class="leading-normal text-md text-gray-650">
              August 2014 - June 2020 | Senior Software Engineer
            </p>
          </header>
          <ul class="">

            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Responsible for maintaining and adding new technologies to the web
              development stack including Drupal 7/8, Symfony 3/4, VueJS, PHP7.3, Algolia,
              Auth0, AWS Stack, CI/CD pipelines, Cypress Integration, Accessibility Testing
              and Material Design Layout.
            </li>
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Responsible for mentoring junior software engineers to empower them to
              become more proficient over time in making better technical contributions.
            </li>
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Responsible for managing the delivery of work within sprint deadlines across
              sprint team without supervision or assistance.
            </li>
          </ul>
        </section>
        <section class="mb-4.5 break-inside-avoid">
          <header>
            <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
              Get Glossy
            </h3>
            <p class="leading-normal text-md text-gray-650">
              April 2013 - August 2014 | Software Engineer
            </p>
          </header>
          <ul class="">
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Responsible for handling multiple projects at one time.
            </li>
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Responsible for improving IT business practices by enhancing web based
              libraries using different technologies (PHP, JavaScript, JQuery, AJAX, Node
              JS, HTML 5 and CSS3).
            </li>
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Responsible for managing IT infrastructure by supporting and troubleshooting
              physical, wireless and remote connections.
            </li>
          </ul>
        </section>
        <section class="mb-4.5 break-inside-avoid">
          <header>
            <h3 class="text-lg font-semibold text-gray-700 leading-snugish">
              Deakin University
            </h3>
            <p class="leading-normal text-md text-gray-650">
              April 2011 - December 2011 | Research Assistant
            </p>
          </header>
          <ul class="">
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Worked on imaging process to convert 2D images into 3D objects.
            </li>
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Researched all the ideas and went through what could be the best solution to
              build something that would work.
            </li>
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Work on graphical simulations.
            </li>
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              Research new ideas for realistic graphical simulations.
            </li>
            <li class="mt-2.1 text-md text-gray-700 leading-normal">
              <span class="absolute -ml-3 sm:-ml-3.2 select-none transform -translate-y-px">›</span>
              C++ to implement graphical simulations.
            </li>
          </ul>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Resume',
  };
</script>
